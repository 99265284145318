<template>
  <div class="h-full p-0 md:p-8">
    <div style="padding:20px 0px; background-color: white; border-radius: 10px;">
      <div class="header flex justify-between">
        <span>{{ $t("common.allTanks") }}</span>
        <!-- <el-button @click="handleCreateBerthClick" size="small" type="primary"
          >Create Jetty</el-button
        > -->
      </div>
      <div class="scrollContainer">
        <!--   -->
        <div v-loading="loading" class="content gap-2 grid grid-cols-2  md:grid-cols-3 md:grid-cols-5 xl:grid-cols-6">
          <div class="item" :class="{'isMobile-item':isMobileScreen}" v-for="(item, index) in list" :key="index">
            <div class="berth-name">
              <p class="title">{{ item.berthName }}</p>
              <span
                v-show="
                  (userInfo.role == 1 && userInfo.userType == 1) ||
                  userInfo.userType === '0'
                "
                class="svgs"
                @click="handleEditClick(item, index)"
              >
                <svg-icon icon-class="amend" />
              </span>
            </div>
            <p class="minLoaTite">{{ $t("jm.minimumLOA") }}</p>
            <p class="loaValue">{{ item.minimumLoa }}</p>
            <p class="maxLoaTite">{{ $t("jm.maximumLOA") }}</p>
            <p class="loaValue">{{ item.maximumLoa }}</p>
            <!-- <div class="flex justify-center" style="margin-top: 25px">
              <el-button
                type="primary"
                size="small"
                @click="handleEditClick(item, index)"
              >
                {{ $t("btn.modify") }}</el-button
              >
            </div> -->
          </div>
        </div>
        <div class="flex flex-wrap justify-center md:justify-end">
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="pageParams.pageNum"
            :limit.sync="pageParams.pageSize"
            @pagination="getList"
          ></pagination>
        </div>
      </div>
    </div>
    <el-dialog
      width="360px"
      :title="isEdit ? $t('jm.modifyBerth') : $t('jm.createBerth')"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="form"
        size="small"
        label-position="top"
        :model="form"
        :rules="rules"
      >
        <el-form-item :label="$t('jm.berthName')" prop="berthName">
          <el-input
            size="small"
            :disabled="true"
            v-model="form.berthName"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('jm.minimumLOA')" prop="minimumLoa">
          <el-input size="small" v-model="form.minimumLoa"></el-input>
        </el-form-item>
        <el-form-item prop="maximumLoa" :label="$t('jm.maximumLOA')">
          <el-input size="small" v-model="form.maximumLoa"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          :loading="btnLoading"
          type="primary"
          size="small"
          @click="handleSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTankList, addTank, editTank } from "@/services/index";
import { mapState } from "vuex";
export default {
  name: "jetty",
  data() {
    return {
      dialogFormVisible: false,
      editData: {
        id: "",
        berthName: "",
        minimumLOA: "",
        maximumLOA: "",
        index: null,
      },
      form: {
        berthName: "",
        maximumLoa: "",
        minimumLoa: "",
      },
      rules: {
        berthName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        maximumLoa: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        minimumLoa: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
      },
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      isEdit: false,
      list: [],
      loading: true,
      btnLoading: false,
      userInfo: {},
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapState(["screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
  },
  methods: {
    handleEditClick(item, index) {
      this.isEdit = true;
      this.dialogFormVisible = true;
      this.form = {
        berthName: item.berthName,
        maximumLoa: item.maximumLoa,
        minimumLoa: item.minimumLoa,
      };
      this.editData = {
        ...this.form,
        id: item.id,
        index: index,
      };
    },
    getList() {
      this.userInfo = this.$store.state.userInfo;
      this.loading = true;
      getTankList(this.pageParams)
        .then((res) => {
          this.loading = false;
          this.list = res.rows;
          this.total = res.total;
        })
        .finally(() => (this.loading = false));
    },
    handleSaveClick() {
      this.$refs.form.validate((res) => {
        if (res) {
          // Todo
          let request;
          this.btnLoading = true;
          if (this.isEdit) {
            request = editTank({ id: this.editData.id, ...this.form });
          } else {
            request = addTank({ ...this.form });
          }
          request
            .then(() => {
              if (!this.isEdit) {
                this.getList();
              } else {
                let target = this.list[this.editData.index];
                this.$set(this.list, this.editData.index, {
                  ...target,
                  ...this.form,
                });
              }
              this.$message.success(this.$t("status.success"));
            })
            .finally(() => {
              this.dialogFormVisible = false;
              this.btnLoading = false;
            });
        }
      });
    },
    handleCreateBerthClick() {
      this.dialogFormVisible = true;
      this.isEdit = false;
      this.form = {
        berthName: "",
        minimumLoa: "",
        maximumLoa: "",
      };
    },
  },
};
</script>

<style scoped lang="scss">
$headerHeight: 80px;
.header {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 0px;
  height: 50px;
  color: #4c565c;
  font-weight: 700;
  font-size: 15px;
  padding: 12px 22px;
}
.scrollContainer {
  width: 100%;
  background: #fcfdff;
  padding: 12px;

  .content {
    overflow-x: hidden;
    // display: flex;
    // flex-direction: row;
    .item {
      background: #ffffff;
      box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);
      padding: 24px 24px 37px;
      box-sizing: content-box;
      border: 1px solid #D5DDED;
      border-radius: 10px;
      .berth-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .svgs {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 50%;
          border: 1.5px solid #82889c;
          width: 35px;
          height: 35px;
          font-size: 15px;
        }
      }
      .title {
        font-size: 24px;
        line-height: 28px;
        color: #4c565c;
      }
      .minLoaTite {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #4c565c;
        margin-top: 21px;
      }
      .loaValue {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        margin-top: 5px;
        color: #4c565c;
      }
      .maxLoaTite {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-top: 26px;
        color: #4c565c;
      }
    }
    .isMobile-item{
     padding: 8px 8px 10px;
      .berth-name {
        .svgs {
          width: 25px;
          height: 25px;
          font-size: 12px;
        }
      }
      .title {
        font-size: 14px;
        line-height: 18px;
      }
      .minLoaTite {
        font-size: 11px;
        line-height: 14px;
        margin-top: 8px;
      }
      .loaValue {
        font-size: 12px;
        line-height:16px;
        margin-top: 3px;
      }
      .maxLoaTite {
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        margin-top: 10px;
      }

    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-dialog__title {
  color: #82889c;
  font-size: 16px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}

</style>
